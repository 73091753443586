import { ECountry } from "./country.model";

export enum EPhoneCode {
  COLOMBIA = '57',
  ENGLAND = '44',
  MEXICO = '52',
}

export interface IPhoneCode {
  country: ECountry;
  label: string;
  value: EPhoneCode;
}

export const PHONE_CODES: IPhoneCode[] = [
  { country: ECountry.COLOMBIA, label: `🇨🇴 +${EPhoneCode.COLOMBIA}`, value: EPhoneCode.COLOMBIA },
  { country: ECountry.ENGLAND, label: `🇬🇧 +${EPhoneCode.ENGLAND}`, value: EPhoneCode.ENGLAND },
  { country: ECountry.MEXICO, label: `🇲🇽 +${EPhoneCode.MEXICO}`, value: EPhoneCode.MEXICO },
];